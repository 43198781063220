import { apiHttpService } from '@/api'
import { getWorkspaceId } from '@/api/utils/route_params'
import { dateInThePast } from '@/api/utils/date_in_the_past'

export default {
  async getDashboardData () {
    const url = '/workspaces/' + getWorkspaceId() + '/dashboard/'
    const scopeInDays = 7
    const date = dateInThePast(scopeInDays)

    try {
      const response = await apiHttpService({
        url: url,
        method: 'GET',
        params: {
          created_at_gte: date
        }
      })
      const data = response.data || {}
      const processedResponse = {
        scopeInDays: scopeInDays,
        count: {
          sent: data.sent_documents ? data.sent_documents : 0,
          inbox: data.inbox_documents ? data.inbox_documents : 0,
          received: data.received_files ? data.received_files : 0,
          integration: data.integration_files ? data.integration_files : 0
        }
      }
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
