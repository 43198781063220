<template>
  <v-row>
    <v-col
      v-for="card in statsListItems"
      :key="card.name"
      cols="12"
      sm="6"
      md="4"
      :class="card.class"
    >
      <v-card
        outlined
        :data-cy="`card-${card.name}`"
      >
        <v-card-title>
          <div
            class="text-h6"
            :data-cy="`ct-${card.name}`"
          >
            {{ card.title }}
            <span
              class="text-caption"
              data-cy="scope-sent"
            >
              / {{ $t('workspaces.dashboard.timeframe', { scopeInDays: dashboardData.scopeInDays }) }}
            </span>
          </div>
        </v-card-title>
        <v-card-title>
          <div
            class="text-h2"
            data-cy="count"
          >
            {{ dashboardData.count[card.name] }}
          </div>
        </v-card-title>
        <v-card-text :class="`${card.color}--text text--darken-4`">
          <span :data-cy="`hint-${card.name}`">{{ card.subtitle }}</span>
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="card.name === 'inbox' || card.name === 'sent'">
          <v-menu open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                rounded
                color="blue darken-4"
                :data-cy="`link-${card.name}`"
                :disabled="!hasWorkspacePermission(card.permission)"
                v-on="on"
              >
                <v-icon left>
                  {{ card.buttonIcon }}
                </v-icon>
                {{ card.buttonLabel }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in documentCategories"
                :key="index"
                :to="{ name: card.routeName , params: { documentCategory: item } }"
                :data-cy="`link-${item}-${card.name}`"
              >
                <v-list-item-title>{{ $t('sidebar.documents.' + item) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            text
            rounded
            color="blue darken-4"
            :data-cy="`link-${card.name}`"
            :disabled="!hasWorkspacePermission(card.permission)"
            :to="{ name: card.routeName }"
          >
            <v-icon left>
              {{ card.buttonIcon }}
            </v-icon>
            {{ card.buttonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/modules/workspaces/dashboard'

export default {
  name: 'DashboardStats',
  data () {
    return {
      documentCategories: ['invoices', 'purchase_orders'],
      dashboardData: {
        scopeInDays: 0,
        count: {
          inbox: 0,
          sent: 0,
          received: 0,
          integration: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ]),
    statsListItems () {
      return [
        {
          name: 'inbox',
          title: this.$t('sidebar.documents.inbox'),
          subtitle: this.$t('workspaces.dashboard.card.subheader.inbox'),
          permission: 'view_documents_document',
          buttonIcon: this.$vuetify.icons.values.inbox,
          buttonLabel: this.$t('workspaces.dashboard.buttons.inbox'),
          routeName: 'DocumentsInbox'
        },
        {
          name: 'sent',
          title: this.$t('sidebar.documents.sent'),
          subtitle: this.$t('workspaces.dashboard.card.subheader.sent'),
          permission: 'view_documents_document',
          buttonIcon: this.$vuetify.icons.values.send,
          buttonLabel: this.$t('workspaces.dashboard.buttons.sent'),
          routeName: 'DocumentsSent'
        },
        {
          name: 'received',
          title: this.$t('sidebar.acquisition.received'),
          subtitle: this.$t('workspaces.dashboard.card.subheader.received'),
          permission: 'view_documents_receivedfile',
          buttonIcon: this.$vuetify.icons.values.received,
          buttonLabel: this.$t('workspaces.dashboard.buttons.received'),
          routeName: 'ReceivedList'
        },
        {
          name: 'integration',
          title: this.$t('sidebar.integration.files'),
          subtitle: this.$t('workspaces.dashboard.card.subheader.integration'),
          permission: 'view_documents_integrationfile',
          buttonIcon: this.$vuetify.icons.values.integration,
          buttonLabel: this.$t('workspaces.dashboard.buttons.integration'),
          routeName: 'IntegrationFilesList'
        }
      ]
    }
  },
  mounted () {
    this.getDashboardData()
  },
  methods: {
    async getDashboardData () {
      const result = await api.getDashboardData()
      this.dashboardData = Object.assign({}, result)
    }
  }
}
</script>
