<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <dashboard-stats />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardStats from '@/features/workspaces/components/DashboardStats'

export default {
  name: 'WorkspacesDashboard',
  components: {
    DashboardStats
  },
  data () {
    return {
      activeTab: null
    }
  }
}
</script>
